import * as React from "react"
import Layout from "../components/layout/en"


import { graphql } from 'gatsby'
import Portfolio from "../components/home/portfolio"

// markup
const PortfolioPage = (props) => {

  return (
    <Layout transparent location={props.location} pageId="portfolio" metaTitle="Porfolio | Crequs">
      <Portfolio trabajos={props.data.allContentfulPortfolio.nodes} portfolioPage={true} lang="en" />
    </Layout>
  )
}

export default PortfolioPage


export const pageQuery = graphql`
{
  allContentfulClientes(limit: 100, sort: {order: ASC, fields: createdAt}, filter:{node_locale:{eq:"en"}}) {
    nodes {
      title
      id
      logo {
        gatsbyImageData(layout: CONSTRAINED, width: 150, quality:100)
      }
    }
  }
  allContentfulPortfolio(filter: {node_locale: {eq: "en"}}) {
    nodes {
      id
      title
      web
      tieneFicha
      slug
      image{
				gatsbyImageData(layout: CONSTRAINED, width: 600, quality:60)
      }
      texto {
        raw
      }
      tipoDeTrabajo{
				id
        name
      }
      
    }
  }
  allContentfulTestimonios(sort: { fields: [createdAt], order: DESC }, filter: {node_locale: {eq: "en"}}){
    nodes {
      nombre
      cargo
      id
      imagen {
        gatsbyImageData(layout: FIXED, width: 70, quality: 70)
      }
      texto {
        raw
      }
    }
  }
}
`
